import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setupSocket, enterBackend, socketOn } from '../../helpers';

import Header from '../../components/Header';

// Actions
import { gameActions, modalActions } from '../../actions';

// Components
const GroupSlots = (props) => {
  const {
    index,
    // group_id,
    ppg,
    roles = [],
    players,
    onRemove,
    // onGroupRemove
  } = props;
  const slots = [...Array(ppg).keys()];
  const slotDivs = slots.map((s) => {
    const role = roles && roles[s] ? roles[s] : '';
    const player = players && players[s] && players[s]._id ? players[s] : null;
    const playerName = player ? (
      `${player.name} ${player.lastname}`
    ) : (
      <i className='text-danger'>INCOMPLETE GROUP</i>
    );
    return (
      <li
        key={`g${index}p${s}`}
        className={`list-group-item ${!player ? 'bg-light' : ''}`}
        style={{ borderStyle: !player ? 'dashed' : 'solid' }}>
        {/* <span className="d-inline-block text-muted mr-3">Group { index+1 }</span> */}
        {roles.length && role ? (
          <small
            className='d-inline-block text-muted mr-3'
            style={{ minWidth: '80px' }}>
            {role}:
          </small>
        ) : null}
        {playerName}
        {player && (
          <button
            className='float-right btn btn-sm btn-outline-danger'
            style={{ fontSize: '10px', padding: '.2rem .4rem' }}
            onClick={(e) => onRemove && onRemove([players[s]._id])}>
            Remove
          </button>
        )}
      </li>
    );
  });
  return (
    <div className='mb-3'>
      {/* <small className="d-inline-block mb-1 text-muted">Group {index+1}</small> */}
      {/* {!players.length && (
        <small className='d-inline-block mb-1 text-muted float-right'>
          <span
            className='btn btn-link btn-sm'
            onClick={(e) => onGroupRemove && onGroupRemove([group_id])}>
            Remove this empty group
          </span>
        </small>
      )} */}
      {slotDivs}
    </div>
  );
};

const setGroups = (players, ppg) => {
  //Validations
  if (!players || !players.length) return [];

  let i,
    j,
    tempArray,
    groupsArray = [],
    chunk = ppg;
  for (i = 0, j = players.length; i < j; i += chunk) {
    tempArray = players.slice(i, i + chunk);
    groupsArray.push({ players: tempArray });
    if (i + chunk >= j) {
      return groupsArray;
    }
  }
};

class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboarding: false,
      item: this.props.game.data || {},
      changed: false,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.launch = this.launch.bind(this);
    this.removePlayers = this.removePlayers.bind(this);
    // this.removeGroups = this.removeGroups.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { item } = this.state;
    // let self = this;

    fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        setupSocket(data.io_domain, () => {
          if (item && item._id) {
            enterBackend({ game_id: item._id }, (gameData) => {
              console.log({ gameData });
            });
            // Sockets!
            socketOn('player', () => {
              dispatch(gameActions.fetchGames(item._id));
            });
            // socketOn('message', () => this.delayedUpdated());
            // socketOn('nodeal', () => this.delayedUpdated());
          }
        });
      });
  }

  componentWillReceiveProps(newProps) {
    // Everytime REDUX updates the store
    // This hook will get executed and the state will get updated
    // console.log({ newProps })
    this.setState({ item: newProps.game.data });
  }

  // componentDidUpdate(prevProps, prevState){
  //     console.log(prevProps, this.props)
  // }

  handleChange(e) {
    let item = { ...this.state.item };
    item[e.target.name] =
      e.target.name === 'players_text'
        ? e.target.value.replace(/\t/g, ', ')
        : e.target.value;
    item['changed'] = true;
    this.setState({ item });
  }

  submit() {
    this.props.dispatch(gameActions.updateGame(this.state.item));
  }

  launch() {
    const { dispatch } = this.props;
    const { item } = this.state;
    if (item && item._id) {
      const modalBody = (
        <p className='mt-3 text-center'>
          You are about to OPEN the session. This will allow learners to access
          their roles and begin the exercise.{' '}
        </p>
      );
      const modalButtons = [
        <button
          key='modal-btn-close'
          className='btn btn-light'
          onClick={() => dispatch(modalActions.close())}>
          Cancel
        </button>,
        <button
          key='modal-btn-delete'
          className='btn btn-success'
          onClick={() => {
            dispatch(gameActions.softLaunch(item));
            dispatch(modalActions.close());
          }}>
          Open
        </button>,
      ];
      // Open the modal
      dispatch(
        modalActions.open({
          title: 'Please Confirm',
          body: modalBody,
          buttons: modalButtons,
        })
      );
    }
  }

  removePlayers(ids) {
    const { dispatch } = this.props;
    const { item } = this.state;
    if (item && item._id) {
      const modalBody = (
        <p className='mt-3 text-center'>
          You are about to REMOVE a learner from the session.
        </p>
      );
      const modalButtons = [
        <button
          key='modal-btn-close'
          className='btn btn-light'
          onClick={() => dispatch(modalActions.close())}>
          Cancel
        </button>,
        <button
          key='modal-btn-delete'
          className='btn btn-danger'
          onClick={() => {
            dispatch(gameActions.removePlayers(item._id, ids));
            dispatch(modalActions.close());
          }}>
          Remove
        </button>,
      ];
      // Open the modal
      dispatch(
        modalActions.open({
          title: 'Please Confirm',
          body: modalBody,
          buttons: modalButtons,
        })
      );
    }
  }

  // removeGroups(group_ids) {
  //   const { dispatch } = this.props;
  //   const { item } = this.state;
  //   if (item && item._id) {
  //     const modalBody = <p className='mt-3 text-center'>¿Confirm?</p>;
  //     const modalButtons = [
  //       <button
  //         key='modal-btn-close'
  //         className='btn btn-light'
  //         onClick={() => dispatch(modalActions.close())}>
  //         Cancel
  //       </button>,
  //       <button
  //         key='modal-btn-delete'
  //         className='btn btn-danger'
  //         onClick={() => {
  //           dispatch(gameActions.removeGroups(item._id, group_ids));
  //           dispatch(modalActions.close());
  //         }}>
  //         Remove
  //       </button>
  //     ];
  //     // Open the modal
  //     dispatch(modalActions.open({ body: modalBody, buttons: modalButtons }));
  //   }
  // }

  render() {
    const { item, onboarding } = this.state;

    // const okPPG = item.players.length % item.ppg === 0 ? true : false;

    if (item.status === 'launched')
      return <Redirect to={'/games/' + item._id + '/players'} />;

    const tempGroups = setGroups(item.players, item.ppg);

    return (
      <div className=''>
        <Header />

        <h2 className='mt-3 sec-title' style={{ lineHeight: 1.3 }}>
          Simulation Setup
          {/* <button className="btn btn-md btn-outline-primary ml-3" onClick={ this.submit } disabled={ item.status === 'saving' ? true : false }>Save</button> */}
          <div className='float-right' style={{ lineHeight: 1 }}>
            {/* <button className="btn btn-md btn-link text-dark" onClick={() => this.setState({ onboarding: true })}>
                        <small className="font-italic">Need help?</small>
                    </button> */}
            <button
              className='btn btn-md btn-success ml-3'
              onClick={this.launch}
              disabled={
                item.status === 'saving' || item.changed ? true : false
              }>
              Open the Session
            </button>
          </div>
        </h2>

        <div className='row mt-4'>
          <div className='col-12 col-lg-6 offset-lg-3'>
            <div className='form-group mb-5'>
              <h5>
                <label>
                  Registered Learners: {item.players ? item.players.length : 0}
                </label>
                {item.players.length % item.ppg !== 0 && (
                  <small
                    style={{
                      fontSize: '12px',
                      padding: '5px 8px',
                      verticalAlign: 'middle',
                    }}
                    className='badge badge-pill badge-danger float-right'>
                    Incomplete group detected
                  </small>
                )}
              </h5>

              {/* {<ul class="list-group">
                  { item.registered.map( rp => <li class="list-group-item">{ rp.email }</li> ) }
              </ul>} */}

              {tempGroups.reverse().map((g, gi) => (
                <GroupSlots
                  key={`slots${gi}`}
                  index={gi}
                  group_id={g._id}
                  ppg={item.ppg}
                  players={g.players}
                  onRemove={this.removePlayers}
                />
              ))}
            </div>
          </div>
        </div>

        <Modal
          isOpen={onboarding}
          toggle={() => this.setState({ onboarding: !onboarding })}
          centered={true}
          size='lg'>
          <ModalHeader>Onboarding</ModalHeader>
          <ModalBody className='text-center'>
            <iframe
              title='vimeo'
              src='https://player.vimeo.com/video/383603029'
              width='640'
              height='360'
              frameBorder='0'
              allow='autoplay; fullscreen'
              allowFullscreen></iframe>
          </ModalBody>
          <ModalFooter>
            <button
              className='btn btn-outline-primary'
              onClick={() => this.setState({ onboarding: !onboarding })}>
              Continue
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { game } = state;
  return {
    game,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Setup);
export { connectedGamesPage as GameSetup };
