import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import numeral from 'numeral';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class DebriefCalculations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { game } = this.props;
    const gameData = game.data;

    // Loop Levels
    const levelsData = gameData.levels.map((l) => {
      // Level data
      const levelResults = gameData.players.reduce(
        (accp, p) => {
          const onlyLevel = p.results.filter((r) => r.level.id === l.id);
          const playerLevelROW = onlyLevel.reduce(
            (placc, plr) => {
              placc[0] += plr.cal_optimal_price.correct ? 0 : 1;
              placc[1] += plr.cal_optimal_price.correct ? 1 : 0;
              return placc;
            },
            [0, 0]
          );
          accp[0] += playerLevelROW[0];
          accp[1] += playerLevelROW[1];
          return accp;
        },
        [0, 0]
      );

      return levelResults;
    });

    // console.log(levelsData);
    const totalData = levelsData.reduce(
      (acc, levelROR) => {
        acc[0] += levelROR[0];
        acc[1] += levelROR[1];
        return acc;
      },
      [0, 0]
    );

    const function_names = [
      'Power Function',
      'Power Function',
      'Power Function',
      'Power Function',
    ];

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Optimal Pricing
          <small class='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3'>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: { text: '' },
              chart: { type: 'column', height: '500px' },
              credits: { enabled: false },
              legend: { enabled: true },
              xAxis: {
                categories: [
                  'Total',
                  ...gameData.levels.map(
                    (l, li) => l.title + '<br/>' + function_names[li]
                  ),
                ],
              },
              yAxis: {
                labels: { style: { fontSize: '14px' } },
                allowDecimals: false,
                min: 0,
                title: {
                  text: '',
                },
                stackLabels: {
                  enabled: true,
                  style: {
                    fontWeight: 'bold',
                    color:
                      (Highcharts.theme && Highcharts.theme.textColor) ||
                      'gray',
                  },
                },
              },
              plotOptions: {
                column: {
                  stacking: 'normal',
                  dataLabels: {
                    enabled: true,
                    //format: '{point.percentage:.1f} %',
                    formatter: function () {
                      if (this.percentage != 0)
                        return numeral(this.percentage).format('0') + '%';
                    },
                  },
                },
                series: {
                  cursor: 'pointer',
                  // borderWidth: 5,
                  // borderColor: 'black',
                },
              },
              series: [
                {
                  name: 'Incorrect',
                  // color: 'rgba(223, 83, 83, .5)',
                  data: [totalData[0], ...levelsData.map((ld) => ld[0])],
                },
                {
                  name: 'Correct',
                  // color: 'rgba(80,180,50, .5)',
                  data: [totalData[1], ...levelsData.map((ld) => ld[1])],
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefCalculations);
