import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

// Images
import image1 from '../../assets/function-1.png';
import image2 from '../../assets/function-2.png';
import image3 from '../../assets/function-3.png';

class Debrief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { game } = this.props;

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Formulas
          <small class='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>
        <div className='mt-3 pt-4 pb-5 bg-white'>
          <div className='row pt-0 pb-5'>
            <div className='col text-center'>
              <h4 className='mb-4'>Power</h4>
              <img className='mb-4' src={image1} alt='' />
              <br />
              <code>
                y = ax<sup>3</sup> + bx<sup>2</sup> + c
              </code>
            </div>
            <div className='col text-center'>
              <h4 className='mb-4'>Product Rule</h4>
              <img className='mb-4' src={image2} alt='' />
              <br />
              <code>y = (x - a)(x - b) + cx</code>
            </div>
            <div className='col text-center'>
              <h4 className='mb-4'>Chain Rule</h4>
              <img className='mb-4' src={image3} alt='' />
              <br />
              <code>
                y = (ax<sup>2</sup> - bx)<sup>2</sup> + c
              </code>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Debrief);
